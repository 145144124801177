$(document).foundation();

// click on hero images to refresh page

// project slideshow

$(document).ready(function(){
  $('.project-slideshow').slick({
    adaptiveHeight: true,
    dots: true,
    fade: true,
    slidesToScroll: 1,
    slidesToShow: 1
  });
});